import React from 'react';
import {
  Avatar, Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import userDataType from '../../shared/types/userData';

const StyledAvatar = styled(Avatar)`
    width: 150px;
    height: 150px;
    & > img {
      margin-bottom: 0;
    }
`;

const Name = styled(Typography)`
  margin-top: 20px;
  font-size: 28px;
`;

Name.displayName = 'name';

const HomeOwnerProfile = ({ userData }) => (
  <Grid container justify="center" direction="column" alignItems="center">
    <Grid item>
      <StyledAvatar alt={userData.UserName} src={userData.Picture} />
    </Grid>
    <Grid item>
      <Name variant="h3">
        {userData.UserName}
      </Name>
    </Grid>
  </Grid>
);

HomeOwnerProfile.propTypes = {
  userData: userDataType.isRequired,
};

export default HomeOwnerProfile;
