import React from 'react';
import PropTypes from 'prop-types';

import MailIcon from '@material-ui/icons/Mail';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import List from '@material-ui/core/List';
import { Link } from 'gatsby';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import styled from 'styled-components';
import { connect } from 'react-redux';
import HomeOwnerProfile from '../homeOwnerProfile/homeOwnerProfile';
import userDataType from '../../shared/types/userData';
import { StyledDrawer } from '../sharedStyles';

const DrawerLink = styled(Link)`
  text-decoration: none;
`;

const links = [{
  title: 'Jobs',
  route: '/jobs',
  icon: <MailIcon />,
}, {
  title: 'Create new Job',
  route: '/jobs/new',
  icon: <MailIcon />,
}, {
  title: 'Chat',
  route: '/chat',
  icon: <MailIcon />,
}, {
  title: 'Property Data',
  route: '/chat',
  icon: <MailIcon />,
}];

const Sidebar = ({ open, handleDrawerClose, userData }) => (
  <StyledDrawer
    variant="persistent"
    anchor="left"
    open={open}
  >
    <div>
      <IconButton onClick={handleDrawerClose}>
        <ChevronLeftIcon />
      </IconButton>
    </div>
    <HomeOwnerProfile userData={userData} />
    <List>
      {links.map(link => (
        <DrawerLink to={link.route} key={link.title}>
          <ListItem button>
            <ListItemIcon>{link.icon}</ListItemIcon>
            <ListItemText primary={link.title} />
          </ListItem>
        </DrawerLink>
      ))}
    </List>
  </StyledDrawer>
);

Sidebar.propTypes = {
  open: PropTypes.bool.isRequired,
  handleDrawerClose: PropTypes.func.isRequired,
  userData: userDataType.isRequired,
};

export default connect(
  state => state.user,
)(Sidebar);
