/* eslint-disable */

import React from 'react'
import PropTypes from 'prop-types'

import './layout.css'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { navigate } from 'gatsby'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import BackIcon from '@material-ui/icons/ArrowBack'

import Grid from '@material-ui/core/Grid'

import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import logo from '../../images/logo-white.png'
import logoIso from '../../images/logo-iso.png'
import Sidebar from '../sidebar/sidebar'
import OptionIcons from './OptionIcons'

const Logo = styled.img`
	max-width: 230px;
	margin-bottom: 0;
`

const LogoIso = styled.img`
	max-width: 35px;
	margin-bottom: 0;
`

const AppContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	height: 100vh;
`

const StyledMain = styled.main`
	background: #152747;
	width: 100%;
	color: #333;
	overflow-y: auto;
	overflow-x: hidden;
	flex: 1;
`

const StyledAppBar = styled(AppBar)`
	&& {
		background-color: #152747;
		box-shadow: none;
		width: 100%:
	}
`

const Helper = styled.a.attrs({ href: 'https://www.fairmarkethome.com' })`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
`

const StyledGrid = styled(Grid)`
	> * {
		width: 33%;
	}
`

const Layout = ({ children, backButton, width }) => {
	const [open, setOpen] = React.useState(false)

	function handleDrawerOpen() {
		if (backButton) {
			navigate(backButton)
		} else {
			setOpen(true)
		}
	}

	function handleDrawerClose() {
		setOpen(false)
	}
	return (
		<AppContainer>
			<StyledAppBar position="static" color="secondary">
				<Toolbar>
					<StyledGrid
						justify="space-between" // Add it here :)
						container
					>
						<Grid item>
							<IconButton color="inherit" aria-label="Open drawer" onClick={handleDrawerOpen}>
								{backButton ? <BackIcon /> : <MenuIcon />}
							</IconButton>
						</Grid>
						<Grid item>
							<Helper>
								{isWidthUp('sm', width) ? <Logo src={logo} alt="logo" /> : <LogoIso src={logoIso} alt="logo" />}
							</Helper>
						</Grid>
						<Grid item>{!backButton && <OptionIcons />}</Grid>
					</StyledGrid>
				</Toolbar>
			</StyledAppBar>
			<Sidebar handleDrawerClose={handleDrawerClose} open={open} />
			<StyledMain>{children}</StyledMain>
		</AppContainer>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
	backButton: PropTypes.string,
	width: PropTypes.string.isRequired,
}

Layout.defaultProps = {
	backButton: undefined,
}

export default connect(state => state.user)(withWidth()(Layout))
