import {
  shape, string, number, object,
} from 'prop-types';

const userDataType = shape({
  Address: string,
  Billing_Information: object, // TODO: describe shape of billing info
  City: string,
  Email: string,
  First_Name: string,
  Homeowner_ID: number,
  Last_Name: string,
  Locked_Time: string,
  Phone_Number: string,
  Picture: string,
  State: string,
  Temporary_Password: string,
  Token: string,
  UserID: number,
  UserName: string,
  User_ID: number,
  User_Type: string,
  Zip: string,
  createdAt: string,
  deletedAt: string,
  updatedAt: string,
});

// TODO: add defaults

export default userDataType;
